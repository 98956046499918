import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import Layout from '../components/Layout'
import useSiteMetadata from '../components/SiteMetadata'

import logoBosch from '../img/bosch.png'
import logoVIR from '../img/vir.png'
import logoLaimbio from '../img/laimbio.png'


export default () => {


    
    const data = useStaticQuery(graphql`
        query {
            heroImage : file(relativePath: { eq: "illustration.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            label : file(relativePath: { eq: "label.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            segment : file(relativePath: { eq: "segment.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            detection : file(relativePath: { eq: "detection.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            series : file(relativePath: { eq: "series.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            pose : file(relativePath: { eq: "pose.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            document : file(relativePath: { eq: "document.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            bosch : file(relativePath: { eq: "bosch.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            vir : file(relativePath: { eq: "vir.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            laimbio : file(relativePath: { eq: "laimbio.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            network : file(relativePath: { eq: "network.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            chain : file(relativePath: { eq: "chain.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            training : file(relativePath: { eq: "training.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const { title, description } = useSiteMetadata();

    return (
        <Layout title={title} description={description}>
            <section className="hero is-fullheight">
            <div className="hero-body">
            
                <div className="container">
                    {/* <p className="title">
                        Fullheight hero with navbar
                    </p> */}
                    <div className="columns">
                        <div className="column is-4">
                            <p className="mb-60 title-gradient">
                                Consultores machine learning
                            </p> 
                            <div className="subtitle-features is-hidden-touch">
                                <p className="subtitle is-5 has-text-grey is-size-4 mb-60">
                                    Automatiza el análisis de tus datos
                                </p>
                                <p className="mb-60"></p>
                                <p className="subtitle is-5 has-text-grey is-size-4 mb-60">
                                    Desbloquea el potencial de tu negocio
                                </p>
                                <p className="mb-60"></p>
                                <p className="subtitle is-5 has-text-grey is-size-4 mb-60">
                                    Explora nuevas oportunidades
                                </p>
                            </div>
                            
                        </div>
                        <div className="column is-8">
                            <Img fluid={data.heroImage.childImageSharp.fluid} alt="Machine learning"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hero-foot">
                {/* <nav className="tabs is-fullwidth">
                <div className="container">
                    <ul>
                    <li className="is-active"><a>Overview</a></li>
                    <li><a>Modifiers</a></li>
                    <li><a>Grid</a></li>
                    <li><a>Elements</a></li>
                    <li><a>Components</a></li>
                    <li><a>Layout</a></li>
                    </ul>
                </div>
            </nav> */}
                {/* <p style={{textAlign: "center"}}>
                            Clientes
                </p> */}
    
            </div>
            </section>

            

            <section className="section has-background-white-bis" style={{padding:'8rem 1.5rem'}}>
                <div className="container">
                <h1 className="has-text-centered is-family-primary is-size-3 mb-40">¿Qué podemos hacer con machine learning?</h1>
                
                {/* probar como https://pingstack.io */}

                <div className="columns is-multiline is-mobile">
                
                    <div className="column is-2-desktop is-4-tablet is-6-mobile">
                        
                        <figure className="image container is-64x64 mb-10" >
                            <Img fluid={data.label.childImageSharp.fluid} alt="Clasificación"/>
                        
                            {/* <img src={label} alt="Clasificación"/> */}
                        </figure>

                        <h5 className="title is-5 has-text-centered">
                            Clasificación
                        </h5>

                    </div>

                    <div className="column is-2-desktop is-4-tablet is-6-mobile">
                        
                        <figure className="image container is-64x64 mb-10" >
                            <Img fluid={data.segment.childImageSharp.fluid} alt="Segmentación"/>
                        </figure>

                        <h5 className="title is-5 has-text-centered">
                            Segmentación
                        </h5>

                    </div>

                    <div className="column is-2-desktop is-4-tablet is-6-mobile">
                        
                        <figure className="image container is-64x64 mb-10" >
                            <Img fluid={data.detection.childImageSharp.fluid} alt="Detección de objetos"/>
                        </figure>

                        <h5 className="title is-5 has-text-centered">
                            Detección de objetos
                        </h5>

                    </div>

                    <div className="column is-2-desktop is-4-tablet is-6-mobile">
                        
                        <figure className="image container is-64x64 mb-10" >
                            <Img fluid={data.series.childImageSharp.fluid} alt="Análisis de series"/>
                        </figure>

                        <h5 className="title is-5 has-text-centered">
                            Análisis de series
                        </h5>

                    </div>

                    <div className="column is-2-desktop is-4-tablet is-6-mobile">
                        
                        <figure className="image container is-64x64 mb-10" >
                            <Img fluid={data.pose.childImageSharp.fluid} alt="Detección de pose"/>
                        </figure>

                        <h5 className="title is-5 has-text-centered">
                            Detección de pose
                        </h5>

                    </div>

                    <div className="column is-2-desktop is-4-tablet is-6-mobile">
                        
                        <figure className="image container is-64x64 mb-10" >
                            <Img fluid={data.document.childImageSharp.fluid} alt="Clasificación de texto"/>
                        </figure>

                        <h5 className="title is-5 has-text-centered">
                            Análisis de texto
                        </h5>

                    </div>

                    

                </div>

                
                </div>
            </section>

            <section className="section" style={{padding:'8rem 1.5rem'}}>

            <div className="container">
            <div className="columns">
                <div className="column is-4 is-offset-1">
                <h1 className="is-family-primary is-size-3">El proceso</h1>
                </div>
            </div>
            
                <div className="columns is-centered is-vcentered">

                    <div className="column is-4">
                        
                        <p className="lh-18">
                            A la hora de incorporar machine learning en tu negocio es recomendable seguir
                            una serie de pasos para maximizar la calidad del producto final. Nuestra experiencia
                            en el desarrollo de algoritmos nos ha llevado a establecer las siguientes fases
                            en el ciclo de vida de un proyecto con machine learning.
                        </p>
                    </div>

                    <div className="column  is-6 mt-40">
                        <ul className="steps">
                            <li className="step-item">
                                <div className="step-wrapper step-wrapper--line">
                                    <div className="step-details step-details--half-bottom-line">
                                    <p className="step-title">Establecer strategia</p>
                                    <p>El primer paso es entender el problema.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="step-item">
                                <div className="step-wrapper step-wrapper--line">
                                    <div className="step-details step-details--full-line">
                                    <p className="step-title">Recoger datos</p>
                                    <p>Construir el conjunto de datos necesarios para entrenar un modelo.</p>
                                    </div>
                                </div>
                            </li>
                            <li className="step-item">
                                <div className="step-wrapper step-wrapper--line">
                                    <div className="step-details step-details--full-line">
                                    <p className="step-title">Desarrollar modelos</p>
                                    <p>Entrenaremos cientos de modelos con los datos propuestos.</p>
                                </div>
                                </div>
                            </li>
                            <li className="step-item">
                                <div className="step-wrapper">
                                    <div className="step-details step-details--half-top-line">
                                    <p className="step-title">Integrar solución</p>
                                    <p>Integramos el mejor modelo acorde a tus necesidades. Tenemos experiencia desde aplicaciones web, a comunicaciones de bajo nivel.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>

                


                

                </div>
                

            </section>


            <section className="section has-background-blue" style={{padding:'8rem 1.5rem'}}>
                
                <div className="container">
                    <h1 className="has-text-white has-text-centered has-text-weight-medium is-family-primary is-size-2 mb-40">Ya confían en nosotros</h1>


                    <div className="columns is-vcentered  is-multiline is-mobile is-centered">
                        <div className="column is-4-desktop is-12-tablet is-12-mobile">
                            <figure className="testimonial">
                                <div className="project-description">
                                    Automatización de la impresión y control de calidad de códigos datamatrix.
                                </div>
                                <div className="client">
                                    <a href="https://vir.es" target="_blank" rel="noopener">
                                        <figure className="is-vertical-center is-128x51 p-8" >
                                            <img src={logoVIR} alt="Laboratorios VIR"/>
                                        </figure>
                                    </a>
                                </div>
                            </figure>
                        </div>
                        <div className="column is-4-desktop is-12-tablet is-12-mobile">
                            <figure className="testimonial">
                                <div className="project-description">
                                    Control de calidad en procesos de fabricación de piezas de automóviles.
                                </div>
                                <div className="client">
                                    <a href="https://www.grupo-bosch.es/nuestra-empresa/bosch-en-espana/madrid-fabrica/" target="_blank" rel="noopener">
                                        <figure className="is-vertical-center is-128x51 p-8" >
                                            <img src={logoBosch} alt="Bosch"/>
                                        </figure>
                                    </a>
                                </div>
                            </figure>
                        </div>
                        <div className="column is-4-desktop is-12-tablet is-12-mobile">
                            <figure className="testimonial">
                                <div className="project-description">
                                    Segmentación de imagen médica para el diagnóstico por resonancia.
                                </div>
                                <div className="client">
                                    <a href="https://www.laimbio.es" target="_blank" rel="noopener">
                                        <figure className="is-vertical-center is-128x51 p-8" >
                                            <img src={logoLaimbio} alt="Laimbio"/>
                                        </figure>
                                    </a>
                                </div>
                            </figure>
                        </div>
                    </div>

                    

                </div>

            </section>    




            <section className="section" style={{padding:'8rem 1.5rem'}}>
                
                <div className="container">
                    <div className="columns">
                        <div className="column is-centered-tablet-portrait">
                            <h2 className="title section-title">Nos elegirás por nuestros servicios</h2>
                            {/* <h3 className="subtitle is-4 is-muted">And we Know it</h3> */}
                            <div className="divider"></div>
                        </div>

                        <div className="column is-8 mt-60">

                            <article className="media icon-box mb-60">
                                <figure className="media-left image container is-64x64" >
                                    <Img fluid={data.network.childImageSharp.fluid} alt="Deep learning"/>
                                </figure>
                                <div className="media-content">
                                    <div className="content">
                                        <p>
                                            <span className="icon-box-title">Deep learning</span>
                                            <span className="icon-box-text">Somos expertos en los últimos avances del deep learning.</span>
                                        </p>
                                    </div>
                                </div>
                            </article>

                            <article className="media icon-box mb-60">
                                <figure className="media-left image container is-64x64" >
                                    <Img fluid={data.chain.childImageSharp.fluid} alt="Servicio de integración"/>
                                </figure>
                                <div className="media-content">
                                    <div className="content">
                                        <p>
                                            <span className="icon-box-title">Integración de software</span>
                                            <span className="icon-box-text">Interfaz web, DLL, servicio REST... Tú eliges cómo integramos tu solución.</span>
                                        </p>
                                    </div>
                                </div>
                            </article>

                            <article className="media icon-box mb-60">
                                <figure className="media-left image container is-64x64" >
                                    <Img fluid={data.training.childImageSharp.fluid} alt="Formación"/>
                                </figure>
                                <div className="media-content">
                                    <div className="content">
                                        <p>
                                            <span className="icon-box-title">Formación</span>
                                            <span className="icon-box-text">Una mejor comprensión aumentará las posibilidades de éxito.</span>
                                        </p>
                                    </div>
                                </div>
                            </article>

      
                        </div>

                    </div>


                </div>

            </section> 


        </Layout>
    )
}